import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommentComponent } from './components/comment/comment.component';
import { EmailLogsComponent } from './components/email-logs/email-logs.component';
import { OtherAnswersComponent } from './components/other-answers/other-answers.component';
import { RenderEmailComponent } from './components/render-email/render-email.component';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PrimeNGModule } from './primeng.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmailLogsHeaderComponent } from './components/email-logs/email-logs-header/email-logs-header.component';
import { EmailLogsBodyComponent } from './components/email-logs/email-logs-body/email-logs-body.component';
const SHARED_MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, FontAwesomeModule];

const COMPONENTS = [
  CommentComponent,
  EmailLogsComponent,
  EmailLogsHeaderComponent,
  EmailLogsBodyComponent,
  OtherAnswersComponent,
  RenderEmailComponent,
];

export const URLS = {
  MENTEES_URL: 'mentees',
  MENTORS_URL: 'mentors',
  COACHES_URL: 'coaches',
  LEADS_URL: 'leads',
  ALL_EMAILS_URL: 'all-emails',
  ALL_FEEDBACKS_URL: 'all-feedbacks',
  REPORTS: 'reports',
};
@NgModule({
  imports: [...SHARED_MODULES, PrimeNGModule],
  declarations: [...COMPONENTS, SafeHtmlPipe, RemoveUnderscorePipe],
  exports: [...SHARED_MODULES, ...COMPONENTS, PrimeNGModule, SafeHtmlPipe, RemoveUnderscorePipe],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
